import MiniSearch from 'minisearch'
import 'select2/dist/js/select2.full.js'

let miniSearch = new MiniSearch({
  fields: ['text', 'aliases', 'constituency_seat_no', 'constituency_seat_name'], // fields to index for full-text search
  storeFields: ['id', 'text', 'aliases', 'constituency_seat_no', 'constituency_seat_name'], // fields to return with search results
  searchOptions: {
    prefix: true,
    fuzzy: 0.2
  }
})

//Variables Init
var all_politician_data = []
var searched_data = []
var search_term
var $blog_list = $("#blog_list");
var blog_list_pagination = 1;
var locale = $("body").data('lang');

document.addEventListener("DOMContentLoaded", function () {
  $.getJSON("politicians.json", function (data) {
    //Format results to suit select2 data
    for (var i = 0; i < data.length; i++) {
      all_politician_data.push({id:data[i]["slug"],text:data[i]["personal_name"],aliases:data[i]["aliases"],constituency_seat_no:data[i]["constituency_seat_no"],constituency_seat_name:data[i]["constituency_seat_name"]});
    }
    miniSearch.addAll(all_politician_data);
    initialize_select2(all_politician_data);
    console.log(all_politician_data);
  });

  loadBlogs();

  $('#load_blogs_btn').on('click', function () {
    loadBlogs();
  });
});

function initialize_select2(data) {
  var search_placeholder = ""

  if(locale == 'en'){
    search_placeholder = 'Enter a name, constituency, code or area';
  }else{
    search_placeholder = 'Cari MP anda melalui nama, nombor kerusi atau kawasan terhubung';
  }
  //using local query instead of ajax to avoid bottlenecking server
  $("#politician_search_field").select2({
    placeholder: search_placeholder,
    containerCssClass: "form-control",
    dropdownAutoWidth: true,
    dropdownCssClass: 'dropdown-menu show',
    multiple: true,
    maximumSelectionSize: 1,
    templateResult: formatPoli,
    templateSelection: formatPoliSelection,
    matcher: matchCustom,
    sorter: sortCustom,
    data: data
  }).on('select2:selecting', function (e) {
    e.preventDefault();
    var url = window.location.protocol + "//" + window.location.host + "/p/" + e.params.args.data.id;
    $(window).attr('location', url);
  });
}

function formatPoli(politician) {
  if (politician.loading) {
    return politician.text;
  }

  var $container = $(
    "<div class='select2-result-politician clearfix'>" +
    "<p class='mb-1 small'><span class='select2-result-politician__seat_no d-inline-flex'></span> <span class='select2-result-politician__seat_name d-inline-block badge badge-soft-dark'></span></p>" +
    "<h3 class='mb-0'><div class='select2-result-politician__name'></h3>" +
    "</div>"
  );

  $container.find(".select2-result-politician__name").prepend(politician.text);
  $container.find(".select2-result-politician__seat_no").append(politician.constituency_seat_no);
  $container.find(".select2-result-politician__seat_name").append(politician.constituency_seat_name);

  return $container;
}

function formatPoliSelection(politician) {
  return politician.text;
}

function matchCustom(params, data) {
  if ($.trim(params.term) === '') {
    return data;
  }

  if (typeof data.text === 'undefined') {
    return null;
  }

  if (params.term != search_term) {
    searched_data = []
    let results = miniSearch.search(params.term)
    $.each(results.slice(0, 9), function (index, value) {
      searched_data.push(value["text"]);
    });
    search_term = params.term
  }

  if (searched_data.indexOf(data.text) > -1) {
    return data;
  }

  return null;
}

function sortCustom(data) {
  return data.sort(function (a, b) {
    return searched_data.indexOf(a.text) - searched_data.indexOf(b.text);
  });
}

function loadBlogs() {
  var xhr = $.getJSON("https://blog.mymp.org.my/wp-json/wp/v2/posts?_embed&page=" + blog_list_pagination + "", function (data) {
    blog_list_pagination++;

    if (blog_list_pagination > xhr.getResponseHeader('x-wp-totalpages')) {
      $("#load_blogs_btn_container").remove();
    }
    $.each(data, function (index, value) {
      var blog_date = new Date(Date.parse(value.date));
      var blog_post_container = "<div class='blog-post mb-5'></div>"
      var blog_post = $.parseHTML(blog_post_container);
      if (value._embedded.hasOwnProperty('wp:featuredmedia')) {
        $(blog_post).append("<div class='mb-3 rounded blog-img shadow-sm'><img class='w-100' src=" + value._embedded['wp:featuredmedia']['0'].source_url + "></div>");
      }
      $(blog_post).append("<p><small>" + blog_date.toDateString() + " | " + value._embedded.author[0].name + "</small></p>");
      $(blog_post).append("<h2 class='featured-title-02'>" + value.title.rendered + "</h2>");
      $(blog_post).append(value.excerpt.rendered);
      $(blog_post).append("<div class='d-block text-right'><a href=" + value.link + " class='btn btn-danger btn-pill btn-sm'>Read More</a></div>");
      $blog_list.append(blog_post);
      $("#blog_preloader").remove();
    });
  });
}